import Vue from 'vue'
import Breakpoints from '../vue/breakpoints'
import LazyLoad from "vanilla-lazyload";
import Swiper, { Pagination, Navigation } from 'swiper';
import MmenuLight from  '../../node_modules/mmenu-light/src/mmenu-light.js';
import tippy from '../../node_modules/tippy.js/dist/tippy-bundle.umd.js';

let carousel = document.querySelector('#carousel');

Swiper.use([Pagination, Navigation]);

// Intiate Vuejs instance
if(carousel == null) { 
    var app = new Vue({
        el: '#app',
        delimiters: ["${","}"],
        components: {
            Breakpoints,
        }
    })
}

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    //Copy link on click
    let copyLink = document.querySelector('#copylink');
    let copySuccess = document.querySelector('.copy-success');

    if(copyLink) {
        copyLink.addEventListener('click', function() {
            let linkValue = this.querySelector('a').getAttribute('data-href');
            let tempTextarea = document.createElement('textarea');
            tempTextarea.value = linkValue;
            document.body.appendChild(tempTextarea);
            tempTextarea.select();
    
            let successful = document.execCommand('copy');
            let msg = successful ? 'Copied' : 'unsuccessful';
    
            copySuccess.innerHTML = msg;
            copySuccess.classList.add('link-copied');
    
            setTimeout(function() {
                copySuccess.classList.remove('link-copied');
            }, 250);
    
            document.body.removeChild(tempTextarea);
    
        });
    }
    

    //Smooth scroll
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            removeActiveClass();
            anchor.classList.add('active');
            e.preventDefault();
    
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });

    function removeActiveClass() {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.classList.remove('active');
        });
    }

    //Mobile menu init // Sync search and menu open
    const menu = new MmenuLight( document.querySelector( "#mobile-menu" ), "(max-width: 1024px)" );
    const navigator = menu.navigation();
    const drawer = menu.offcanvas({
        position: "right"
    });

    const navOpen = document.querySelector('.js-open-nav');
    const navClose = document.querySelector('.js-close-nav');
    const projectWrapper = document.getElementById('app');

    // Open Menu
    bindEvent(navOpen, "click", function() {
        projectWrapper.classList.add('js-is-open');
        drawer.open();
    }, false);

    // Close Menu
    bindEvent(navClose, "click", function() {
        projectWrapper.classList.remove('js-is-open');
        drawer.close();
    }, false);

    // Lazyload
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    //Banner swiper
    var titles = [],
        els = document.querySelectorAll('.banner-swiper .swiper-slide'),
        i = 0;

        for (i = 0; i < els.length; i++) {
            if (els[i]) {
                titles.push(els[i].dataset.title);
            }
        }

    const bannerSwiper = document.getElementById('banner-swiper');

    if(bannerSwiper){    
        const bannerSwiper = new Swiper ('.banner-swiper', {
            grabCursor: true,
            //slidesPerView: 1,

            pagination: {
                el: '.swiper-pagination',
                clickable: true,

                renderBullet: function (index, className) {
                  return '<span class="' + className + '">' + '<span>' + titles[index] + '</span>' + '</span>';
                },
            },
        });
    }

    //Desktop menu mouse events
    const menuItem = document.querySelectorAll(".sub-menu"), len = menuItem.length;

    for (let i = 0; i < menuItem.length; i++) {
        menuItem[i].addEventListener('mouseenter', function(e) { 
            e.preventDefault();
            this.classList.add('open');
        });
        menuItem[i].addEventListener('mouseleave', function(e) { 
            e.preventDefault();
            this.classList.remove('open');
        });
    }

    // Overlay functionality
    const overlayTriggers = document.querySelectorAll('.js-open-overlay');
    const overlayProduct = document.getElementById('overlay-product');
    const close = document.getElementById('js-close-modal');
    const modal = document.querySelector('.js-modal');
    const app = document.getElementById('app');

    if(overlayTriggers.length){ 
        for (var i = 0; i < overlayTriggers.length; i++) {
            overlayTriggers[i].addEventListener('click',  function(e) {
                e.stopPropagation()
                overlayProduct.classList.add('show');

                const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                projectWrapper.style.position = 'fixed';
                projectWrapper.style.top = `-${scrollY}`;
            });
        }

        if(close){
            close.addEventListener('click', function(){
                overlayProduct.classList.remove("show");

                const scrollY = projectWrapper.style.top;
                projectWrapper.style.position = '';
                projectWrapper.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            });
        }

        if(overlayProduct){
            //Closes the modal when the document is clicked
            overlayProduct.addEventListener('click', () => {
                overlayProduct.classList.remove('show');
                
                const scrollY = projectWrapper.style.top;
                projectWrapper.style.position = '';
                projectWrapper.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            });
        }

        //Prevents the click event from reaching the document
        app.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        if(modal){
            modal.addEventListener('click', (e) => {
                e.stopPropagation();
            });
        }

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }

    //Product swiper
    const productSwiper = document.getElementById('product-swiper');

    if(productSwiper){    
        const productSwiper = new Swiper ('.product-swiper', {
            grabCursor: true,

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
        });
    }

    //Tool tips
    document.querySelectorAll('a[data-template]').forEach(btn => {
        tippy(btn, {
            content(reference) {
                const id = reference.getAttribute('data-template');
                const template = document.getElementById(id);
                return template.innerHTML;
            },
            allowHTML: true
        })
    })

    //Panels
    let tabs = Array.from(document.querySelectorAll('.tabs li a'));
    let panel = Array.from(document.querySelectorAll('.panel'));

    const handleClick = (e) => {
        e.preventDefault();

        tabs.forEach(node => {
            node.classList.remove('active');
        });

        panel.forEach(node => {
            if (node.classList.contains('not-active')) {
                node.classList.remove('not-active'); 
            } else {
                node.classList.add('not-active');  
            }
        });

        e.currentTarget.classList.add('active');  
    }

    tabs.forEach(node => {
        node.addEventListener('click', handleClick)
    });


    // Accordions
    // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = e.target;
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})
